@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles start */

body {
  color: #1d212c !important;
  font-size: 13px;
}

#root {
  font-family: "Inter", sans-serif;
}

.modal-view {
  min-width: 500px;
}

.circular-thumbnail {
  border: solid 2px #673ee4;
}

.chronos-blue {
  background: #e2e8ff;
  color: #333f66;
}

.activity-project-name {
  display: inline-block;
  border-radius: 20px;
  background-color: #e2e8ff;
  padding: 3px 12px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333f66;
  font-size: 13px;
  font-weight: 500;
  min-height: 24px;
}

.activity-card .title {
  font-size: 16px;
  padding: 5px;
  background-color: #e2e8ff;
  /* margin-left: 10px;
  margin-right: 10px; */
}

.css-ahj2mt-MuiTypography-root {
  font-size: 14px !important;
}

.google-button {
  color: rgb(68, 68, 68);
  padding: 15px;
  border: solid 1px rgb(176 176 176);
  transition: background 0.5s;
}

.google-button:hover {
  background: #171923;
  color: #fff;
}

.google-icon {
  width: 25px;
  height: 25px;
  float: left;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* sidebar */
.sidebar {
  background: #171923;
}

.menu-active {
  background: #333f66;
}

.menu-active:hover {
  background: #333f66;
}

.menu-active-container {
  background: #202c2f;
}

.org-title {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

/* Navbar */
.navbar {
  height: 65px;
}

/* tiles*/
.tile-box {
  width: 82rem;
}

.tile-header {
  color: #1d212c;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

/* Members tile */
.avatar {
  background: #fbe77f;
  border: solid 2px #f6921e;
  width: 30px;
  height: 30px;
}

.avatar-normal {
  width: 150px;
  height: 150px;
}

.avatar-full {
  width: 100%;
  height: 100%;
}

.avatar-150 {
  width: 150px;
  height: 150px;
}

.members-avatar {
  width: 30px;
  height: 30px;
}

.avatar-icon {
  color: #f6921e;
}

/* Project Section */
.project-header {
  color: #1d212c;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.project-search-lable {
  position: relative;
}

.project-search-lable:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 16px;
  color: antiquewhite;
  margin: 10px;
  background: url("./img/icons/project/Vector.svg") center no-repeat;
}

.project-search-close {
  content: "";
  position: absolute;
  right: 0px;
  top: -2px;
  bottom: 0;
  width: 16px;
  margin: 10px;
}

.project-search-close2 {
  content: "";
  position: relative;
  right: 0px;
  top: -41px;
  bottom: 0;
  width: 16px;
  margin: 10px;
  color: #979797 !important;

}
.project-search-close2:hover{
  color: #000000 !important;
}

.project-search-input {
  padding: 10px 45px;
  width: 252px;
  height: 38px;
}

.assignee-search-close {
  content: "";
  position: absolute;
  right: 0px;
  /* top: -2px; */
  bottom: 0;
  width: 16px;
  margin: 10px;
}

.nav-tabs .nav-link {
  color: #1d212c !important;
  font-weight: 400 !important;
}

.nav-tabs .nav-link.active {
  color: #1d212c !important;
  font-weight: 500 !important;
  border-color: #1d212c !important;
}

/* timeline chart style */
.timeline {
  width: 100%;
  padding: 5px;
}

.timeline .timeline-full {
  background-color: rgb(226, 226, 226);
  height: 15px;
  border-radius: 25px;
  margin: 5px;
}

/* timeline chart style */

.honeybee-datepicker {
  /* margin-top: 20px !important; */
  height: 20px !important;
  padding: 0px !important;
}

.honeybee-datepicker input {
  padding: 5px 15px !important;
}

.honeybee-datepicker input:hover {
  cursor: pointer !important;
}

.honeybee-timepicker {
  /* margin-top: 20px !important; */
  height: 20px !important;
  padding: 0px !important;
}

.honeybee-timepicker input {
  padding: 5px 15px !important;
}

.honeybee-timepicker input:hover {
  cursor: pointer !important;
}

.honeybee-select {
  /* margin-top: 20px !important; */
  height: 35px !important;
  padding: 0px !important;
}

.honeybee-select input {
  padding: 5px 15px !important;
}

.honeybee-select input:hover {
  cursor: pointer !important;
}

.hbt-bg-dark {
  background: #171923;
}

.hbt-bg-dark:hover {
  background: #2f3242;
}

.honeybee-label {
  font-size: small;
  color: #171923;
  text-transform: uppercase;
  font-weight: 600;
}

.honeybee-input {
  /* margin-top: 20px !important; */
  height: 20px !important;
  padding: 0px !important;
}

.honeybee-input input {
  /* margin-top: 20px !important; */
  padding: 8px !important;
}

.honeybee-input button {
  /* margin-top: 20px !important; */
  padding: 8px !important;
}

.page-title {
  font-size: 18px !important;
}

.right {
  float: right !important;
}

.left {
  float: left !important;
}

.hbt-yellow {
  background-color: #fde047 !important;
  transition: background-color 0.5s;
}

.hbt-yellow:hover {
  background-color: #fd4d47 !important;
  transition: background-color 0.5s;
}

.btn-hbt-yellow {
  background-color: #fde047 !important;
}

.disabled {
  opacity: 0.5 !important;
  cursor: default !important;
  /* signup page */
}

input[type="checkbox"] {
  accent-color: #f6921e;
  border: 1px solid black;
}

.btn-shadow {
  box-shadow: 0px 0px 4px #0d1018;
}

.projectColorCircle {
  width: 15px !important;
  height: 15px !important;
  border-radius: 25px !important;
}

.timeframe>.active {
  color: #000000 !important;
  background: #fff !important;
  box-shadow: 1px 1.5px 3px #00000046;
}

.timeframe>a:hover {
  cursor: pointer !important;
}

.timeframe>a.disabled {
  cursor: not-allowed !important;
}

.disabled {
  cursor: not-allowed !important;
}

.screenshout-count-lable {
  border: 1px solid #eee;
}

.screenshout-count-lable>.screenshout-count {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

.screenshout-count-lable:hover>.screenshout-count {
  visibility: visible;
  opacity: 1;
}

.btn-shadow {
  box-shadow: 0px 0px 4px #0d1018;
}

/* DateRangePicker custom styles */

:not(.rdrDayPassive)>.rdrDayNumber>span {
  color: #000000 !important;
}

.rdrDayNumber>span::after {
  background-color: #fbe77f;
}

.rdrStaticRangeSelected {
  color: #000000 !important;
  background-color: #fbe77f !important;
}

.rdrStaticRangeLabel:hover,
.rdrStaticRangeSelected>.rdrStaticRangeLabel {
  background-color: #fbe77f !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: #ffb30f !important;
}

.rdrSelected {
  color: transparent !important;
}

.calendar-icon {
  color: #f6921e;
}

/* DateRangePicker custom styles ends */
.org-item {
  cursor: pointer;
}

.org-item:hover {
  background-color: #eee;
}

.org-menu-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  -ms-scroll-chaining: none;
  padding-bottom: 5px;
  max-height: calc(100vh - 46px - 140px);
  overflow-x: hidden;
}

.navbar-menu:hover {
  background-color: #fde047 !important;
  transition: background 0.5s;
}

.custom-file-input {
  display: inline-block;
  background: #fde047;
  border: 1px solid #fde047;
  border-radius: 3px;
  padding: 10px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  text-align: center;
  transition: background 0.5s, border 0.5s;
}

.custom-file-input:hover {
  border-color: #ffb30f;
  background: #ffb30f;
}

.custom_cropper>.reactEasyCrop_Container {
  position: relative !important;
  width: 100%;
  height: 400px;
}

.imageUploader-btn-hbt {
  background: #fde047;
  color: #000000;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.imageUploader-btn-hbt:hover {
  background: #ffb30f;
  color: #000000;
}

.filter-panel {
  background-color: #00000010;
  transition: all 3s;
  opacity: 1;
}

.ht-link {
  color: #ffb30f !important;
}

.filter-panel-show {
  display: block;
  right: -301px;
  z-index: 100;

  webkit-animation: fadeInFromNone 0.3s ease-out;
  -moz-animation: fadeInFromNone 0.3s ease-out;
  -o-animation: fadeInFromNone 0.3s ease-out;
  animation: fadeInFromNone 0.3s ease-out;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    right: -301px;
    opacity: 0;
  }

  1% {
    display: block;
    right: -301px;
    opacity: 0;
  }

  100% {
    display: block;
    right: 0px;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    right: -301px;
    opacity: 0;
  }

  1% {
    display: block;
    right: -301px;
    opacity: 0;
  }

  100% {
    display: block;
    right: 0px;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    right: -301px;
    opacity: 0;
  }

  1% {
    display: block;
    right: -301px;
    opacity: 0;
  }

  100% {
    display: block;
    right: 0px;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    right: -301px;
    opacity: 0;
  }

  1% {
    display: block;
    right: -301px;
    opacity: 0;
  }

  100% {
    display: block;
    right: 0px;
    opacity: 1;
  }
}

/* Filter Panel*/

.filter {
  background: #fff;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 300px;
  height: 100vh;
  z-index: 100;
  transition: all 2s;
}

.notiflix-block {
  position: fixed !important;
}

.fc .fc-button-primary {
  background-color: #171923;
  border-color: #171923;
  color: #fff;
  outline: none !important;
}

.fc .fc-button-primary:disabled {
  background-color: #17192390;
  border-color: #17192390;
  color: #fff;
}

.fc .fc-button-primary:focus {
  outline: 0 !important;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 0px;
}

.fc th.fc-day-today {
  background-color: #fde047 !important;
}

.fc-scrollgrid {
  border: none !important;
}

.fc .fc-scrollgrid-section {
  height: 25px !important;
}

.fc-timegrid .fc-timegrid-slots tbody tr td:first-child {
  border: none;
}

.fc-timegrid .fc-timegrid-slots td.fc-timegrid-slot {
  position: relative;
  bottom: -23px;
  color: #71717a;
  padding: 2px;
  /* height: 1000px; */
}

.fc {
  width: 100%;
  display: block;
}

.border-none {
  border: none !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

#react-select-3-listbox div {
  background-color: #fff !important;
  color: #000000 !important;
  cursor: pointer;
}

#react-select-3-listbox div div:hover {
  background-color: #fde047 !important;
  color: #000000 !important;
}

.notification-box {
  padding: 15px;
  font-size: 13px;
  background-color: #e9f8ff;
  border: 1px solid #bbe9ff;
  color: #369fd1;
  border-radius: 5px;
}

.sign_up_text {
  margin-top: 60px;
}

.sign_in_text {
  margin-top: 40px;
}

@media (max-width: 760px) {
  @media (min-height: 720px) {
    .sign_up_text {
      margin-top: 80px;
    }
  }
}

@media (max-height: 886px) {
  .sign_up_text {
    margin-top: 18px;
  }

  .sign_in_text {
    margin-top: 12px;
  }
}

@media (min-width: 1024px) {
  .fc th.fc-day {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    background-color: #f9fafb;
  }

  body .fc,
  .fc table,
  .fc tr,
  .fc td {
    font-size: 12px !important;
  }

  .fc {
    position: relative !important;
    overflow-x: auto !important;
  }
}

@media (max-width: 1024px) {
  .fc th.fc-day {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    background-color: #f9fafb;
  }
}

#invite_member_role_select option {
  width: 400px !important;
}

.ht-border {
  border: solid 1px #ddd;
}

.ht-border:active {
  border: solid 1px #fde047;
}

.ht-border option {
  padding: 40px;
  border: solid 1px #fde047;
}

.ht-input {
  @apply mt-1 px-3 text-sm py-1 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-yellow-500 focus:ring-yellow-500 block w-full rounded-md;
}

.ht-input-rounded {
  @apply sm:text-sm text-xs bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-slate-500 focus:ring-slate-500 block sm:w-[300px] rounded-full py-[10px] px-[45px] h-[38px] w-full;
}

.ht-btn-yellow-lte {
  @apply inline-flex h-10 px-5 transition-colors duration-150 bg-yellow-300 rounded focus:outline-none hover:shadow-lg w-full items-center mb-2;
}

.ht-btn-yellow {
  @apply bg-[#FBE77F] hover:bg-[#FADE4B] font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mr-1;
}

.ht-btn-yellow-disabled {
  @apply bg-gray-300 text-[#171923] hover:bg-[#FADE4B] font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mr-1;
}

.ht-btn-black {
  @apply text-white bg-[#1D212C] hover:bg-[#202C2F] font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mr-1;
}

.ht-btn-red {
  @apply bg-red-600 text-white hover:bg-red-800 active:bg-red-500 font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mr-1;
}

.ht-btn-red-disabled {
  @apply bg-gray-400 text-white active:bg-red-500 font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 mr-1;
}

.ht-selected-label-yellow {
  @apply m-1 flex flex-wrap justify-between items-center text-gray-600 rounded px-2 py-1 font-bold cursor-pointer border border-l-4 border-[#FADE4B];
}

.ht-selected-label-orange {
  @apply m-1 flex flex-wrap justify-between items-center text-gray-600 rounded px-2 py-1 font-bold cursor-pointer border border-l-4 border-[#ffb30f];
}

.ht-page-header {
  font-size: 18px;

  @apply text-[#1D212C] font-semibold;
}

.ht-modal-header {
  font-size: 22px;
  text-transform: capitalize;

  @apply text-[#1D212C] font-semibold;
}

.ht-modal-title {
  font-size: 16px;
  text-transform: capitalize;

  @apply text-[#1D212C] font-semibold;
}

.ht-table-tr {
  @apply border-b hover:bg-gray-50;
}


.modal-input {
  @apply mt-1 mb-0 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 block w-full rounded-md focus:outline-none focus:border-yellow-300
}

.modal-input-danger {
  @apply mt-1 mb-0 px-3 py-2 bg-white border shadow-sm border-red-300 placeholder-red-400 block w-full rounded-md focus:outline-none
}

.modal-input-danger>input {
  @apply placeholder-red-400
}

.modal-input-danger>input:focus {
  @apply placeholder-gray-400
}

.select-input>div {
  margin-top: 0.25rem !important;

}

#react-select-2-listbox>div>.css-d7l1ni-option {
  background-color: #ffec9a !important;
  cursor: pointer !important;
}

.select-input>.css-t3ipsp-control {
  margin-top: 0.25rem;
  border: solid 1px #fade4b !important;
  border-color: #fade4b !important;
  border-style: solid !important;
  border-width: 1px !important;
  box-shadow: none !important;
}

.css-tr4s17-option {
  background-color: #fade4b !important;
  color: #000 !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.dropdown-disabled {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  border-width: 1px;
  background-color: #f8f8f8;
  color: #dddddd;
  cursor: not-allowed;

  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.375rem;
}

.dropdown-input {
  display: block;
  width: 100%;
  border-width: 1px;
  min-height: 39px;

  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.375rem;
}

.dropdown-input-focus {
  display: block;
  width: 100%;
  border-width: 1px;
  min-height: 39px;

  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;

  border: solid 1px #fade4b;
  /* border-bottom-color: transparent; */
  border-radius: 0.375rem 0.375rem 0rem 0rem;
  border-bottom: #fff;
  border-radius: 0.375rem  0.375rem 0rem 0rem;
}

.dropdown-list {
  border: solid 1px #fade4b;
  border-top: none;
  border-radius: 0rem 0rem 0.375rem 0.375rem;
}

.select-inbox {
  width: 100% !important;
}

.required {
  color: #ff0000 !important;
}

.email-field-close {
  position: relative;
  right: 10px !important;
  margin-right: 20px;
  top: 35px;
  color: #ff0000 !important;
  font-weight: bold !important;

}

.inputWithButton {
  position: relative;
}

.inputWithButton input {
  /* width: 70%;
    height: 25px;
    padding-right: 60px; */
}

.inputWithButton button {
  position: absolute;
  right: 0;
  top: 0px;
  background-color: #000000;
  color: #fff;
  padding: 9px 25px;
  text-align: center;
  border-radius: 0px 5px 5px 0px;

  @apply uppercase font-bold
}

.inputWithButton button.clicked {
  position: absolute;
  right: 0;
  top: 0px;
  background-color: #ffb30f;
  color: #000;
  padding: 9px 20px;
  text-align: center;
  border-radius: 0px 5px 5px 0px;

  @apply uppercase font-bold
}

.text-xsm {
  font-size: 11px !important;
  line-height: normal;
  padding: 5px 0px;
}

/* Custom Styles end */
polygon{
  stroke-width:2
}

.rdrMonth{
  padding: 0 !important;
}

.rdrInputRange {
  padding: 0px 20px !important;
}

.datepicker-danger > div{
  border: #ff0000 solid 1.5px !important;
}

.datepicker-danger > div > fieldset.MuiOutlinedInput-notchedOutline{
  border: none !important;
}

 .demo-app > div.fc{
  position: relative !important;
  top: -60px !important;
 }


 /* datetime picker custom style */

 .css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #FADE4B !important;
  color: #000 !important;

 }

 .css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover{
  background-color: #ffb30f !important;
 }

 .css-umzx0k-MuiClock-pin,  .css-d0vs79-MuiClockPointer-root, .css-rdq5h4-MuiClockPointer-root{
  background-color: #fade4b !important;
 }

 .css-eg3pzz-MuiClockPointer-thumb{
  background-color: #fade4b !important;
  border: 16px solid #fade4b !important;
 }

 .css-12t0dn4-MuiClockPointer-thumb{
  background-color: #fade4b !important;
  border: 16px solid #fade4b !important;
 }

 .css-zddqwy-MuiClockNumber-root.Mui-selected{
  color: #000 !important;
 }

 .css-kjd0cg-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton, .css-1wahiw3-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton{
  background-color: #fade4b !important;
  color: #000 !important;
 }

 /* datetime picker custom style end */


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffee96; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FADE4B;
  cursor: pointer !important;
}


/* Custom Styles end */
